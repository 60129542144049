import React from 'react';
import styled from 'styled-components';
import { SiteLayout } from '../components/Layout/SiteLayout';
import { Link } from '../components/Link';

const Container = styled.header(
  ({ theme: { tablet, laptop } }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  color: var(--color-light);
  background-color: var(--color-dark);

  background-image: url(//ucarecdn.com/d3cfffa1-a215-465c-98b8-1da243041763/space.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 272vw;

  ${tablet()} {
    background-size: cover;
  }

  ${laptop()} {
    background-size: contain;
  }
`
);

const Content = styled.div(
  ({ theme: { tablet, laptop } }) => `
  margin: 18vh 0;

  ${tablet()} {
    margin: 24vh 0;
  }

  ${laptop()} {
    margin: 37vh 0;
  }
`
);

const Text = styled.p`
  margin: 32px 0;
`;

export const frontmatter = {
  title: 'Page not found',
  description: 'Sorry, but the page you were looking for doesn’t exist.',
  noindex: true,
};

const NotFound = ({ location }) => (
  <SiteLayout meta={frontmatter} pathName={location.pathname}>
    <main>
      <Container>
        <Content>
          <h1>Page not found</h1>
          <Text>Sorry, but the page you were looking for doesn’t exist.</Text>
          <Link to="/" size="l" visual="rounded-light">
            Go to main page
          </Link>
        </Content>
      </Container>
    </main>
  </SiteLayout>
);

export default NotFound;
